import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropzone } from '@commonsku/styles';

import { closePopup, createSpinnerPopup } from '../actions/popup';
import { createUploadGuestFile } from '../actions/file';
import { createFinalizeUpdateArtwork } from '../actions/artwork';

import { getImageSrc } from '../utils';
import { BASE_ZINDEX } from '../popup-factory';

import Img from './Img';

class SelectShopArtworkPopup extends Component {

  render() {
    const { artwork_files, onClosePopup, onUploadImage, onSelectArtwork, index } = this.props;

    return (
      <div className="reveal" style={{ height: 'auto', display: 'block', zIndex: BASE_ZINDEX + index }}>
        <div className="row small-12 columns">
          <h3>Select Artwork</h3>
          <a className="button" style={{ position: 'fixed', top: '1rem', right: '1rem' }} onClick={onClosePopup}>Close</a>
          <a className="button" style={{ position: 'fixed', top: '1rem', right: '6rem' }}>
            <Dropzone style={{ border: 'none' }} onDrop={onUploadImage}>
              <><i className="fi-upload" style={{ fontSize: '0.9em' }}></i>&nbsp;&nbsp;Upload Image</>
            </Dropzone>
          </a>
        </div>
        <div className="row">
          {artwork_files.map(a =>
            <div key={a.file_id} className="small-6 medium-4 large-3 columns end" onClick={() => onSelectArtwork(a.file_id)} style={{ cursor: 'pointer' }}>
              <Img src={getImageSrc(a)} />
            </div>)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  artwork_files: Object.values(state.entities.files).filter(f => 'GUEST' === f.parent_id)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onUploadImage: image => Promise.resolve(
    dispatch(createSpinnerPopup('Uploading...'))
  ).then(
    () => dispatch(createUploadGuestFile('GUEST', 'GUEST', image[0], ownProps.tenant_id)).then(
      action => dispatch(createFinalizeUpdateArtwork({ artwork_id: ownProps.artwork_id, artwork_file_id: action.payload.id }))
    ),
    () => dispatch(closePopup())
  ).then(
    () => {
      dispatch(closePopup());
      return dispatch(closePopup());
    }
  ),
  onSelectArtwork: file_id => {
    dispatch(createFinalizeUpdateArtwork({ artwork_id: ownProps.artwork_id, artwork_file_id: file_id }));
    return dispatch(closePopup());
  }
});

const ConnectedSelectShopArtworkPopup = connect(mapStateToProps, mapDispatchToProps)(SelectShopArtworkPopup);
export default ConnectedSelectShopArtworkPopup;
